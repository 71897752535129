export const fieldTranslations = {
	customerType: {
		PRIVATE: 'Kotitalous',
		ORGANIZATION: 'Yritys',
		FARM: 'Maatila',
		SOK: 'SOK',
	},
	farmCustomerType: {
		PRIVATE: 'Kotitalous',
		ORGANIZATION: 'Yritys',
	},
	bonusCard: {
		YES: 'Kyllä',
		NO: 'Ei',
		PARALLEL: 'Rinnakkaiskortti',
	},
	creditCheckAllowed: {
		true: 'Lupa tarkastaa luottotiedot',
		false: 'Ei lupaa tarkastaa luottotiedot',
	},
	creditsChecked: {
		OK: 'Kaikki kunnossa',
		MARK: 'Merkintä',
	},
	creditsInOrder: {
		YES: 'Kyllä',
		NO: 'Ei',
	},
	organizationSize: {
		single: 'Yksinyrittäjä',
		// NOTE zeroToTwo is 1-2, due to later change but not changing the name. Name change would break the database.
		zeroToTwo: '1-2 henkilöä',
		threeToNine: '3-9 henkilöä',
		tenOrMore: 'Yli 10 henkilöä',
	},
	organizationRevenue: {
		lessThan10k: 'Alle 10 000 €',
		'10kOrMore': '10 000 € tai enemmän',
	},
	informedClient: {
		InsurancePolicyToMeeting:
			'Ottaa vakuutuskirjat tapaamiseen mukaan / esille',
		NearbyInsurancePolicy: 'Vakuutuskirjojen ääressä',
		NearbyComputer: 'Asiakas on tietokoneen ääressä',
		NotAtHand: 'Ei pysty olemaan',
		NotAsked: 'En kysynyt',
	},
	informedBinding: {
		Yes: 'Kyllä',
		Other: 'Muu, ks. vapaa sana',
	},
	invoiceType: {
		COMPETITOR: 'Kilpailija',
		OWN: 'Omakanta',
		COMPETITOR_SPLIT: 'Kilpailijan alueyhtiöjako',
	},
	customerSource: {
		trafi: 'Trafi-lista',
		ihmiset: 'Ihmiset-lista',
		teha: 'TEHA-kohde',
		desk: 'DESK-kohde',
		sasi: 'SOK-kohde',
	},
	cancelType: {
		CANCEL: 'Siirrettävä tapaaminen',
		POSTPONE: 'Peruutettu/ei saapunut',
	},
	meetingType: {
		WEB: 'Verkko',
		PHONE: 'Puhelin',
		LOCATION: 'Tapaamispaikka',
		CUSTOMERS_LOCATION: 'Asiakkaan luona',
	},
	status: {
		UNHANDLED: 'Käsittelemätön',
		CONFIRMED: 'Vahvistettu',
		CANCELED: 'Peruutettu',
		UNHANDLED_RETRIAL: 'Käsittelemätön - palautettu käsittelyyn',
		CANCELED_RETRIAL: 'Peruutettu - palautettu käsittelyyn',
		COMPLETED: 'Toteutunut',
	},
	cancelReason: {
		NO_NOTICE: 'Ei ilmoitusta',
		UNANSWERED_CALL: 'Ei vastannut puheluun',
		CHANGED_MIND: 'Mieli muuttunut',
		HINDRANCE: 'Työeste / meno',
		SICKNESS: 'Sairastuminen',
		CREDIT: 'Luottotiedot',
		OTHER: 'Muu',
	},
}

export const mapTranslationsToArray = (translationKey) =>
	Object.keys(fieldTranslations[translationKey]).map((key) => {
		const isBoolean =
			key === 'true' || key === 'false' ? (key === 'true' ? true : false) : null

		return {
			id: isBoolean != null ? isBoolean : key,
			name: fieldTranslations[translationKey][key],
		}
	})

export default fieldTranslations
